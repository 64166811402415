// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

//import carddata from "./data.json"

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { receiveFlashcardData, purgeFlashcardData } from './actions';

import "./AppStyles.css";

import MainMenu from "./MainMenu";
import WebFont from 'webfontloader';
import Spinner from 'react-spinkit';

function LoadingPage() {

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  const deepEqual = (obj1: any, obj2: any) => {
    const jsonString1 = JSON.stringify(obj1);
    const jsonString2 = JSON.stringify(obj2);
    return jsonString1 === jsonString2;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://raw.githubusercontent.com/ntpcai/data/main/elight/data/data.json'
        );
        const data = await response.json();

        if (!deepEqual(data, flashcarddata)) {
          // If they are not equal, update the persisted data
          dispatch(receiveFlashcardData(data));
        }

      } catch (error) {
        console.error('Error fetching flashcarddata:', error);
      }
    };

    let carddata: any;

    try {
      // Try to import carddata if available
      carddata = require('./data.json'); // Use `import` if supported by your environment
    } catch (e) {
      // Import failed, use a fallback mechanism
      carddata = null; // or a default value
    }
    
    if(carddata) {
       dispatch(receiveFlashcardData(carddata));
    }
    else if(!flashcarddata) {
      fetchData();
    }
  }, []);

  useEffect(() => { 
    WebFont.load({
      custom: {
        families: ['frontfont', 'backfont', 'radicals'],
      },
    });
  }, []);


  useEffect(() => {
    document.fonts.ready.then(function () {
      if( flashcarddata ) { setLoading(false); } 
    });
  });


  return (
    loading ? (
      // Display the spinner while loading is true
      <div className="nav">
        <div className="loadscreen">
          {/* Add your spinner component or loading message here */}
          <Spinner name="line-scale" color="steelblue" />
          正在載入...
        </div>
      </div>
    ) : (
      <MainMenu />
    )
  );
}

export default LoadingPage;
